import { useAccountStore } from '@/vantool/stores/account'
import api from '@/vantool/utils/axios'

export default {
  todayVan() {
    const params = {
      van: useAccountStore().account?.current_van?.id || null
    }

    return api.get('/bookings/today/', { params }).then(({ data }) => {
      this.state.bookings = data
      return data
    })
  },

  todayAll() {
    return api.get('/bookings/today/').then(({ data }) => {
      this.state.allBookings = data
      return data
    })
  },

  futureVanAll() {
    const params = {
      future: true,
      van: useAccountStore().account?.current_van?.id || null
    }
    return api.get('/bookings/today/', { params }).then(({ data }) => {
      return data
    })
  },

  futureAll() {
    const params = {
      future: true
    }
    return api.get('/bookings/today/', { params }).then(({ data }) => {
      return data
    })
  }
}
